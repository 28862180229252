/* TestimonialManagement.css */
.testimonial-card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    position: relative;
  }
  
  .testimonial-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  }
  
  .testimonial-image-container {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 15px;
    flex-shrink: 0;
  }
  
  .testimonial-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .testimonial-description {
    margin-top: 15px;
    font-size: 0.95rem;
    color: #555;
    max-height: 150px;
    overflow-y: auto;
  }
  
  .testimonial-actions {
    display: flex;
    gap: 10px;
  }
  
  .rating-stars {
    display: flex;
    align-items: center;
  }
  
  /* Styling for ReactQuill editor */
  .ql-editor {
    min-height: 150px;
  }
  
  .ql-container {
    font-size: 16px;
  }
  
  /* Style for modal */
  .modal-content {
    border-radius: 8px;
  }
  
  .modal-header {
    background-color: #f8f9fa;
    border-bottom: 1px solid #eaeaea;
  }
  
  .modal-footer {
    border-top: 1px solid #eaeaea;
  }

  .ql-editor ol {
    list-style-type: decimal;
  }
  
  /* Testimonial Management Styles */

/* Testimonial Card */
.testimonial-card {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  }
  
  .testimonial-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
  
  /* Testimonial Image */
  .testimonial-image-container {
    width: 60px;
    height: 60px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 15px;
    flex-shrink: 0;
  }
  
  .testimonial-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  /* Testimonial Description - Rich Text Styling */
  .testimonial-description {
    margin-top: 15px;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }
  
  /* This is critical - import Quill styles for the display */
  .testimonial-description.ql-editor {
    padding: 0;
    min-height: auto;
  }
  
  /* Make sure lists display correctly */
  .testimonial-description.ql-editor ul {
    padding-left: 1.5em;
  }
  
  .testimonial-description.ql-editor ol {
    padding-left: 1.5em;
  }
  
  /* Maintain proper image sizing */
  .testimonial-description.ql-editor img {
    max-width: 100%;
    height: auto;
  }
  
  /* Space between actions buttons */
  .testimonial-actions .btn {
    margin-right: 10px;
  }
  
  /* Modal fixes */
  .modal-body .ql-container {
    height: 200px !important;
  }