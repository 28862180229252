.containers {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 cards per row */
  gap: 20px; /* Adjust spacing between cards */
  padding: 20px;
}

.admincards {
  background-color: #005F73;
  color: white;
  padding: 20px;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  transition: background 0.3s ease;
}

.admincards:hover {
  background-color: #186a7aef;
}
/* ReceptionistPage.css */
.receptionist-page {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
 
  
  .btn {
    padding: 8px 16px;
    margin: 5px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .back-btn {
    background-color: #f44336;
    color: white;
  }
  
  .add-btn {
    background-color: #4CAF50;
    color: white;
  }
  

  
  .receptionist-table {
    width: 100%;
    margin-top: 20px;
    border-collapse: collapse;
  }
  
  .receptionist-table th, .receptionist-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: center;
  }
  
  .receptionist-table th {
    background-color: #f2f2f2;
    color: #333;
  }
  
  .receptionist-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .receptionist-table tr:hover {
    background-color: #f1f1f1;
  }

/* Popup Styles */
.popupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popupContainer {
  background: white;
  padding: 2rem;
  width: 600px;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.popupForm {
  display: flex;
  flex-direction: column;
}



.formRow {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
}

.formGroup {
  display: flex;
  flex-direction: column;
}

.formLabel {
  font-weight:500;
  font-size: 15px;
  margin-top: 9px;
  margin-left: 2px;
}

.formInput,
.dropdown {
  /* padding: 9rem; */
  height: 50px;
  font-size: 1rem;
  border: 2px solid #0A9396;
  border-radius: 5px;
}

.checkboxGroup {
  margin-top: 12px;
  display: flex;
}





.pairRow {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-bottom: 1rem;
}

.addButton,
.submitButton,
.cancelButton {
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 1rem;
}

.submitButton {
  background: #28a745;
  color: white;
}

.cancelButton {
  background: #dc3545;
  color: white;
}



.button:hover {
  opacity: 0.9;
}
.assignlist{
  height: 90px;
  overflow-y: auto;
}
.pairContainer {
  max-height: 200px; /* Adjust height as needed */
  overflow-y: auto;
  padding: 10px;
  background-color: whitesmoke;
  /* border:1.5px solid #0a8082; */
  border-radius: 4px;
  margin-top: 10px;
}
.norecord{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  background-color: #ddd ;
  border-radius: 6px;
  padding: 16px;
}
.table-header th {
 background-color: #0A9396;
 color: white;
}
.headings{
  font-size: 25px;
  font-weight: 500;
  color: #0f8a8c;
}
.searchbar{
  border: 2px solid #0f8a8c;
  width: 300px;
  margin-right:12px ;
  padding: 8px;
  border-radius: 5px;
}
.addbtn{
  background-color:#0A9396 ;
  white-space: nowrap;
  padding: 2px 9px;
  border-radius: 5px;
  &:hover{
    background-color: #005F73;
  }
  

}
